import UnitTranslationSelectorPlugin, { TranslationsLoader } from '@edx/unit-translation-selector-plugin';
import { DatadogLoggingService } from '@edx/frontend-logging';
import { LockPaywall, UpgradeNotification, NextUnitButton } from '@edx/frontend-plugin-advertisements';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';

const modifyProgressCertificateWidget = (widget) => {
  const { RenderWidget } = widget;
  if (RenderWidget.props.id.includes('upgrade')) {
    widget.RenderWidget = UpgradeNotification({ id: 'progress_certificate_status_slot' });
  }
  
  return widget;
};

// Load environment variables from .env file
const config = {
  ...process.env,
  AI_TRANSLATIONS_URL: 'https://ai-translations.edx.org',
  loggingService: DatadogLoggingService,
  OPTIMIZELY_FULL_STACK_SDK_KEY: 'B1ha73pEGEG2rbcVZSRGj',
  pluginSlots: {
    unit_title_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'unit_title_widget',
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: UnitTranslationSelectorPlugin,
          },
        },
      ],
    },
    sequence_container_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: "sequence_container_widget",
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: () => <div id="whole-course-translation-feedback-widget" />,
          },
        },
      ],
    },
    gated_unit_content_message_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'gated_unit_content_message_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: LockPaywall,
          },
        },
      ],
    },
    notification_widget_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_widget_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    notification_tray_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_tray_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    outline_tab_notifications_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'outline_tab_notifications_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    next_button_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'next_button_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: NextUnitButton,
          },
        },
      ],
    },
    content_iframe_loader_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: "content_iframe_loader_slot",
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: TranslationsLoader,
          },
        },
      ],
    },
    progress_certificate_status_slot: {
      keepDefault: true,
      plugins: [{
        op: PLUGIN_OPERATIONS.Modify,
        widgetId: 'default_contents',
        fn: modifyProgressCertificateWidget,
      }]
    },
  },
};

export default config;
